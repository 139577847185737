import React from 'react';
import Layout from "../layout";
import TitleLists from "../title-lists";
import ServiceResultsList from "../service-results-list";
import sanitizeHtml from 'sanitize-html';
import sanitizeOptions from "../../helper/sanitizeOptions";
import {globalHistory as history} from '@reach/router';
import ServiceInternalsList from "../service-internals";
import MageButton from "../mageButton";
import scrollTo from 'gatsby-plugin-smoothscroll';
import ServiceAccordion from '../service-accordion';

const ServicePage = ({service, serviceCategories, caseStudies}) => {
    const sanitizeOpt = sanitizeOptions();
    const page = service.servicesCustomFields || {};
    const {title, seo, additionalMetaTag} = service;
    const caseStudiesTitle = service.caseStudiesTitleBlock.block || {};
    // const category = service?.serviceCategories?.nodes[0] || {};
    const {whatWeDoLandingCustomFields, caseStudiesTitleBlock} = serviceCategories;
    // Set breadcrumbs
    const servisesQuestionsLeft = service.servisesQuestionsLeft;
    const {location} = history;
    const getCrumbs = Array.isArray(whatWeDoLandingCustomFields?.servicesBreadcrumbs) && whatWeDoLandingCustomFields.servicesBreadcrumbs;
    const LastCrumb =
        {
            crumblabel: service?.title,
            pathname: location.pathname
        };
    const crumbs = [...getCrumbs, LastCrumb];
    const letsChatBlockId = 'lets-chat';
    // Set metadata
    const seoData = {...{seo}, additionalMetaTag, title};

    return (
        <Layout
            crumbs={crumbs}
            setClass={'what-we-do-inner__content'}
            seoData={seoData}>
            <div className={'what-we-do-inner'}>
                {!!page?.innerTitle && (
                    <div className={'page-content what-we-do-inner__content'}>
                        <h1 className={'what-we-do-inner__title h2-title black'}
                            dangerouslySetInnerHTML={{__html: sanitizeHtml(page.innerTitle, sanitizeOpt.title)}}/>
                    </div>
                )}
                {!!service?.content && (
                    <div className={'what-we-do__page-content'}
                         dangerouslySetInnerHTML={{__html: sanitizeHtml(service.content, sanitizeOpt.contentData)}}/>
                )}
                {!!page?.internalList && (
                    <div className={'what-we-do__page-content service-internals'}>
                        <ServiceInternalsList
                            fieldGroupName={page.internalList.fieldGroupName}
                            list={page.internalList.list}
                            title={page.internalList.title}
                        />
                        <div className={'service-internals__button'}>
                            <MageButton
                                clickEvent={() => scrollTo(`#${letsChatBlockId}`)}
                                color={`#EC5C2B`}
                                size={180}
                                text={whatWeDoLandingCustomFields.letsChatButton}
                                textColor={`white`}/>
                        </div>
                    </div>
                )}
                {!!whatWeDoLandingCustomFields?.howItHelps && (
                    <div className={'what-we-do__page-content'}>
                        <ServiceResultsList
                            title={!!page?.howItHelps?.title ? page.howItHelps.title : whatWeDoLandingCustomFields.howItHelps.title}
                            threeColumns={!!page?.howItHelps?.threeColumns ? page.howItHelps.threeColumns : whatWeDoLandingCustomFields.howItHelps.threeColumns}
                            list={!!page?.howItHelps?.list ? page.howItHelps.list : whatWeDoLandingCustomFields.howItHelps.list}
                        />
                    </div>
                )}
                {!!servisesQuestionsLeft?.anyQuestionsLeft && (
                    <div class={'service-accordion page-content'}>
                        <ServiceAccordion
                            title={servisesQuestionsLeft.title}
                            titlePath={servisesQuestionsLeft.anyQuestionsLeft.title}
                            descPath={servisesQuestionsLeft.anyQuestionsLeft.content}
                            items={servisesQuestionsLeft.anyQuestionsLeft}
                        />
                    </div>
                )}
                <div className={'page-content what-we-do-inner__case-studies case-studies-block-container'}>
                    <TitleLists data={{
                        customFields: 'case_studies_block',
                        titleH2: !!caseStudiesTitle?.titleH2 ? caseStudiesTitle.titleH2 : caseStudiesTitleBlock?.block?.titleH2,
                        titleH5: !!caseStudiesTitle?.titleH5 ? caseStudiesTitle.titleH5 : caseStudiesTitleBlock?.block?.titleH5,
                        description: !!caseStudiesTitle?.description ? caseStudiesTitle.description : caseStudiesTitleBlock?.block?.description,
                        link: !!caseStudiesTitle?.linkTarget ? caseStudiesTitle.linkTarget : caseStudiesTitleBlock?.block?.linkTarget,
                        button: !!caseStudiesTitle?.linkText ? caseStudiesTitle.linkText : caseStudiesTitleBlock?.block?.linkText,
                        caseStudies: caseStudies,
                    }}/>
                </div>
            </div>
            {!!letsChatBlockId && (
                <div id={letsChatBlockId}/>
            )}
        </Layout>
    );
}

export default ServicePage;
import React from 'react';
import {graphql} from 'gatsby';
import ServicePage from '../../components/template-parts/service-part';

export default ({data}) => <ServicePage {...data} />

export const query = graphql`
    query services($id: String!) {
        service: wpService(id: { eq: $id }) {
            id
            title
            content
            serviceCategories {
                nodes {
                    name
                    link
                }
            }
            servicesCustomFields {
                isTopInLanding
                shortDescription
                innerTitle
                serviceScope { 
                    title
                    list {
                        description
                        title
                    }
                }
                serviceOffers {
                    title
                    list {
                        description
                        title
                    }
                }
                howItHelps {
                    fieldGroupName
                    title
                    threeColumns
                    list {
                        description
                    }
                }
                internalList {
                    fieldGroupName
                    title
                    list {
                        description
                    }
                }
            }
            caseStudiesTitleBlock {
                block {
                    titleH2
                    titleH5
                    description
                    linkTarget
                    linkText
                }
            }
            servisesQuestionsLeft {
                title
                anyQuestionsLeft {
                    title
                    content {
                        contentWsw
                        internalList {
                            description
                        }
                    }
                }
            }
            seo {
                    title
                    metaDesc
                    canonical
                }
            additionalMetaTag {
                    keywords
                    ogDescription
                    ogTitle
                    ogType
                    ogImage {
                        mediaItemUrl
                    }
                    twitterImage{
                        mediaItemUrl
                    }
                    twitterTitle
                    twitterDescription
            }
        }
        
        serviceCategories: wpPage(slug: {eq: "what-we-do"}) {
                whatWeDoLandingCustomFields {
                    title
                    titleContent
                    letsChatButton
                    howItHelps {
                        fieldGroupName
                        title
                        threeColumns
                        list {
                            description
                        }
                    }
                    categoriesBlock {
                        title
                        subTitle
                    }
                    servicesBreadcrumbs {
                        crumblabel
                        pathname
                    }
                }
                caseStudiesTitleBlock {
                    block {
                        titleH2
                        titleH5
                        description
                        linkTarget
                        linkText
                    }
                }
            }
        
        caseStudies: allWpCaseStudy(
            limit: 2
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                id
                slug
                title
                content
                link
                contentType {
                    node {
                        graphqlSingleName
                        name
                    }
                }
                caseStudySingleFeatured {
                    additionalFields {
                        titleH5
                        clientFigures
                    }
                }
                caseStudyRegions {
                    nodes {
                        id
                        name
                    }
                }
                caseStudyIndustries {
                    nodes {
                        id
                        name
                    }
                }
                caseStudyTechnologies {
                    nodes {
                        id
                        name
                    }
                }
                info {
                    aboutClient
                    aboutClientShort
                    brand
                    result
                    gridImage {
                        remoteFile {
                            childImageSharp {
                                fluid(quality: 70) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        title
                    }
                }
            }
        }
    }
`
